/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/ondas.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: linear-gradient(
    black 70%,
    transparent
  );
}
.banner .tagline {
  border-radius: 10PX;
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(73, 170, 54, 0.5) -5.91%, rgba(80, 189, 47, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
   border-radius: 15px;
}

/* Estilos para los bordes de los extremos (opcional) */


.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #36aa4f -5.91%, #2fbd2f 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  border: 6px solid gold;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #36aa40 -5.91%, #57bd2f 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
/* .contact {
  background: linear-gradient(90.21deg, #59f061 -5.91%, #013b09 111.58%);
  padding: 60px 0 200px 0;
} */


.stars-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Asegúrate de que el canvas esté detrás del contenido */
}

.stars-canvas {
  width: 100%;
  height: 100%;
}

.content-container {
  position: relative;
  z-index: 1;
}



.stars-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.content-container {
  position: relative;
  z-index: 1;
}

.contact {
  position: relative;
  overflow: hidden;
  min-height: 100vh; /* Para asegurarse de que ocupe toda la altura de la ventana */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact img {

  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}


.navbar img {
  width: 70px;
  height: auto;
}


/* Estilos generales del modal */
.transparent-modal .modal-content {
  background-color: rgba(13, 150, 43, 0.9); /* Fondo verde más oscuro */
  border: none;
  color: #f0f0f0; /* Texto en blanco suave para mejor contraste */
  border-radius: 16px; /* Bordes más redondeados */
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
}

/* Título del modal */
.transparent-modal .modal-title {
  font-size: 1.3rem; /* Título más grande */
  font-weight: 600;
  text-align: center; /* Centrar el título */
}

/* Contenido del modal */
.transparent-modal .modal-body {
  padding: 24px;
  max-height: 80vh;
  overflow-y: auto;
}

/* Párrafos dentro del modal */
.transparent-modal .modal-body p {
  line-height: 1.8; /* Interlineado más amplio para mayor legibilidad */
  margin-bottom: 16px;
}

/* Listas dentro del modal */
.transparent-modal .modal-body ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 16px;
}

/* Botón de cerrar */
.transparent-modal .btn-secondary {
  background-color: #fff;
  color: #0d962b;
  text-transform: none;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px 20px; /* Botón más grande */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15); /* Sombra más definida */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Transiciones suaves */
  &:hover {
    background-color: #f5f5f5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
  }
}

/* Estilos para los items del carrusel */
.item {
  text-align: center; /* Centrar el contenido */
}

.item img {
  max-width: 100%;   /* Imagen responsiva */
  height: auto;
  margin-bottom: 10px; /* Espacio entre la imagen y el título */
}

.item h5 {
  font-weight: 500;
}

.item p {
  line-height: 1.6;
}

.item ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
}


#confetti-container {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; 
  z-index: 9999; 
}

.donate-button {
  /* ... tus estilos existentes */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Sombra inicial sutil */
}

.donate-button.glowing {
  box-shadow: 0 0 20px #4CAF50, 0 0 40px #4CAF50, 0 0 60px #4CAF50; /* Brillo verde */
}

/* Estilos generales para el botón */
.donate-wrapper {
  display: flex;
  justify-content: flex-start; /* Alinea el botón a la izquierda */
  align-items: center;
  margin-bottom: 20px; /* Espacio inferior opcional */
}

.donate-button {
  display: inline-flex; /* Permite alinear el icono y el texto */
  align-items: center;
  background: linear-gradient(to right, #4CAF50, #2E8B57); /* Gradiente verde */
  color: white;
  padding: 12px 24px; /* Más padding para mayor visibilidad */
  text-decoration: none; /* Elimina el subrayado del enlace */
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra sutil */
  transition: all 0.3s ease; /* Transiciones suaves */
}

.donate-button:hover {
  transform: translateY(-2px); /* Efecto de elevación al pasar el mouse */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Sombra más intensa al pasar el mouse */
}

.donate-icon {
  margin-right: 8px; /* Espacio entre el icono y el texto */
  font-size: 20px; /* Ajusta el tamaño del icono */
}

/* Animación de brillo */
.donate-button.glowing {
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  to {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 10px #4CAF50, 0 0 20px #4CAF50; /* Brillo más sutil */
  }
}


.crypto-info {
  display: flex;
  flex-direction: column;
}

.crypto-info p {
  text-align: justify;
  text-justify: inter-word;
  margin: 0; /* Elimina todos los márgenes */
  /* (Opcional) Mejora la justificación en algunos navegadores */
}

/* Estilos para los enlaces en el modal */
.transparent-modal .modal-body a {
  display: flex;
  align-items: center; /* Alinea el icono y el texto verticalmente */
  margin-top: 10px;   /* Espacio superior para separar del contenido anterior */
  color: white;       /* Color del texto */
  text-decoration: none; /* Quitar subrayado por defecto */
}

.transparent-modal .modal-body a:hover {
  text-decoration: underline; /* Subrayado en hover */
}

/* Estilos específicos para el icono de WhatsApp */
.transparent-modal .modal-body .whatsapp-link svg { /* Selecciona el icono SVG */
  margin-right: 8px;  /* Espacio entre el icono y el texto */
  color: #25D366;   /* Color verde de WhatsApp */
}

/* Estilos específicos para el icono de enlace externo */
.transparent-modal .modal-body .website-link svg { /* Selecciona el icono SVG */
  margin-right: 8px;  /* Espacio entre el icono y el texto */
  color: #007bff;   /* Color azul para el enlace de la página web */
}


/* En tu archivo CSS */
.benefit-image {
  width: 100%;
  height: 200px; /* Puedes ajustar esta altura según tus necesidades */
  object-fit: cover; /* Asegura que la imagen se escale y recorte sin distorsión */
  border-radius: 10px; /* Si quieres bordes redondeados */
}


.project {
  margin-top: 0;
  padding: 60px 0; /* Ajusta el padding según sea necesario */
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%, 
    rgba(0, 51, 0, 1) 0%, /* Verde muy oscuro */
    rgba(0, 44, 44, 1) 90% /* Verde oscuro con tono grisáceo */
  );
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Benefits {
  margin-top: 0;
  background-image: linear-gradient(to bottom, #121212 85%,  #003300 100%);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.skill{
  margin-top: 0;
  background-image: linear-gradient(to top, #121212 75%,  #003300 100%);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}


.stars-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Asegúrate de que el canvas esté detrás del contenido */
}

.stars-canvas {
  width: 100%;
  height: 100%;
}

.content-container {
  position: relative;
  z-index: 1;
}



.stars-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.content-container {
  position: relative;
  z-index: 1;
}

.contact {
  position: relative;
  overflow: hidden;
  min-height: 100vh; /* Para asegurarse de que ocupe toda la altura de la ventana */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Estilos para el botón en dispositivos móviles */
@media (max-width: 576px) {
  .submit-button {
    margin-bottom: 1rem; /* mb-3 en Bootstrap es equivalente a 1rem */
  }
}

.soysacha .soysacha-heading {
  font-size: 4vmin; /* Ajusta este valor para cambiar el tamaño de la fuente */
  position: relative;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  background: linear-gradient(90deg, #00ff00, #004400, #028363); /* Degradado de verde a negro */
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 5s ease-in-out infinite;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


.benefit-image {
  width: 100%; /* Asegúrate de que la imagen ocupe todo el ancho del contenedor */
  height: auto; /* Mantén la proporción de la imagen */
  border-radius: 100%; /* Hace que la imagen sea redonda */
  object-fit: cover; /* Asegura que la imagen se recorte bien dentro del contenedor redondeado */
}



.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro para mayor visibilidad */
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  width: 60px; /* Ajusta el tamaño según necesites */
  height: 60px; /* Ajusta el tamaño según necesites */
}

.carousel-arrow-left {
  left: 0;
}

.carousel-arrow-right {
  right: 0;
}

.carousel-arrow svg {
  width: 100%; /* Para que el icono se ajuste al contenedor */
  height: 100%; /* Para que el icono se ajuste al contenedor */
  color: white; /* Color del icono */
}
